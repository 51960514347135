<template>
<div class="tw-w-full tw-flex tw-h-screen tw-items-center tw-justify-center tw-flex-col awaiting">
  <img src="@/assets/awaiting-verification-icon.svg" alt="logo" style="width: 166px; height: 166px" />
    <h6 class="awaiting-header">Verification In Progress</h6>
    <h6 class="awaiting-desc tw-mt-3">Give us some few minutes to get you verified.</h6>
    <v-divider  class="tw-mt-10 divider" />
  <p class="need-help tw-mt-8">Need help? <span style="color: #004aad" class="tw-cursor-pointer"
                                                @click="$router.push({name:'HomePage'})">Contact us</span>
                                        </p>
</div>
</template>

<script>

export default {
  name: "CompanyAwaitVerification",
  data(){
    return{

    }
  },
  methods:{

  },
  created() {

  }
}
</script>

<style scoped lang="scss">
.awaiting{
  background-image: url("../../assets/awaiting-verification.png");
  background-size: cover;
  background-position: center;
}

.awaiting-header{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: #333333;
  text-align: center;
}

.awaiting-desc{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #333333;
  width: 15%;
  @media screen and (max-width: 1024px){
    width: 90%;
  }
}

.need-help{
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #333333;
}

.divider{
  width: 20%;
  @media screen and (max-width: 1024px){
    width: 90%;
  }
}
</style>